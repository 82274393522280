<template>
    <div class="login-layout__background login-background">
        <div class="login-background__logo">
            <img src="@/assets/img/login/logo-new.png" alt="logo">
        </div>
        <img src="@/assets/img/login/girl.webp" alt="" class="login-background__figure">
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>

</style>
